<template>
    <header class="header-global">
    
            <div class="container" style="padding: 6px; display: flow-root; vertical-align: middle; ">
                <span>Atendimento com
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#333333"><path d="M103.2,17.2c-6.33533,0 -11.46667,5.13133 -11.46667,11.46667v22.93333c0,6.33533 5.13133,11.46667 11.46667,11.46667v11.46667l17.2,-11.46667h22.93333c6.33533,0 11.46667,-5.13133 11.46667,-11.46667v-22.93333c0,-6.33533 -5.13133,-11.46667 -11.46667,-11.46667zM44.36615,28.68906c-0.71908,-0.05957 -1.45116,0 -2.18359,0.17917c-2.84373,0.69373 -6.53286,2.37844 -10.40286,6.24844c-12.11453,12.11453 -18.54196,32.53721 27.00937,78.09427c45.55133,45.55707 65.97401,39.12964 78.09427,27.00937c3.88147,-3.88147 5.5659,-7.57579 6.25964,-10.42526c0.7052,-2.91827 -0.55515,-5.92182 -3.14661,-7.43542c-6.4672,-3.784 -20.42446,-11.96574 -26.8974,-15.75547c-2.12706,-1.24413 -4.73752,-1.22201 -6.85312,0.03359l-10.70521,6.38282c-2.39653,1.4276 -5.39058,1.26635 -7.59218,-0.44792c-3.8012,-2.96987 -9.9141,-7.9714 -15.24036,-13.29193c-5.32627,-5.32627 -10.3278,-11.43916 -13.29193,-15.24036c-1.71427,-2.19587 -1.88125,-5.19566 -0.44792,-7.59219l6.38282,-10.70521c1.26133,-2.1156 1.26653,-4.74846 0.02239,-6.87552l-15.72188,-26.84141c-1.1438,-1.9436 -3.12816,-3.15826 -5.28542,-3.33698z"></path></g></g></svg>
                </span>
                <span> (47) 3346-5283</span>
                <span class="float-right">
                    <a href="#" class="btn btn-dark btn-sm"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" height="15" viewBox="0 0 172 172" style=" fill:#f9f9f9;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#f9f9f9"><path d="M136.7185,35.31733c-13.51633,-13.5235 -31.49033,-20.97683 -50.63967,-20.984c-39.45967,0 -71.5735,32.0995 -71.58783,71.55917c-0.00717,12.61333 3.2895,24.92567 9.55317,35.776l-9.71083,35.99817l37.50317,-8.86517c10.45617,5.70467 22.22383,8.7075 34.2065,8.71467h0.02867c39.4525,0 71.56633,-32.10667 71.58783,-71.55917c0.01433,-19.12783 -7.42467,-37.109 -20.941,-50.63967zM121.10233,111.47033c-1.49067,4.17817 -8.7935,8.20583 -12.07583,8.49967c-3.28233,0.301 -6.35683,1.4835 -21.46417,-4.472c-18.18183,-7.16667 -29.66283,-25.80717 -30.5515,-26.99683c-0.89583,-1.19683 -7.30283,-9.6965 -7.30283,-18.49717c0,-8.80067 4.6225,-13.12933 6.26367,-14.91383c1.64117,-1.79167 3.57617,-2.236 4.773,-2.236c1.18967,0 2.3865,0 3.42567,0.043c1.27567,0.05017 2.6875,0.11467 4.02767,3.08883c1.591,3.54033 5.06683,12.384 5.51117,13.27983c0.44433,0.89583 0.74533,1.94217 0.1505,3.13183c-0.59483,1.18967 -0.89583,1.935 -1.7845,2.98133c-0.89583,1.04633 -1.87767,2.32917 -2.68033,3.13183c-0.89583,0.88867 -1.8275,1.86333 -0.78833,3.64783c1.04633,1.79167 4.62967,7.64683 9.94733,12.384c6.837,6.09167 12.59183,7.9765 14.3835,8.8795c1.79167,0.89583 2.83083,0.74533 3.87717,-0.4515c1.04633,-1.18967 4.472,-5.21733 5.66167,-7.009c1.18967,-1.79167 2.3865,-1.49067 4.02767,-0.89583c1.64117,0.59483 10.43467,4.9235 12.21917,5.81933c1.79167,0.89583 2.98133,1.34017 3.42567,2.0855c0.44433,0.73817 0.44433,4.3215 -1.04633,8.49967z"></path></g></g></svg>
                        Whatsapp SAC
                    </a>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#333333"><path d="M156.23893,0h-140.47786c-8.5944,0 -15.76107,7.16667 -15.76107,15.76107v140.47786c0,8.5944 7.16667,15.76107 15.76107,15.76107h70.23893v-71.66667h-21.5v-21.5h21.5v-21.5c0,-17.91667 7.16667,-28.66667 28.66667,-28.66667h21.5v21.5h-9.32226c-6.43881,0 -12.17774,5.73893 -12.17774,12.17774v16.48893h28.66667l-3.58333,21.5h-25.08333v71.66667h41.57226c8.5944,0 15.76107,-7.16667 15.76107,-15.76107v-140.47786c0,-8.5944 -7.16667,-15.76107 -15.76107,-15.76107z"></path></g></g></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#333333"><path d="M57.33333,21.5c-19.78717,0 -35.83333,16.04617 -35.83333,35.83333v57.33333c0,19.78717 16.04617,35.83333 35.83333,35.83333h57.33333c19.78717,0 35.83333,-16.04617 35.83333,-35.83333v-57.33333c0,-19.78717 -16.04617,-35.83333 -35.83333,-35.83333zM129,35.83333c3.956,0 7.16667,3.21067 7.16667,7.16667c0,3.956 -3.21067,7.16667 -7.16667,7.16667c-3.956,0 -7.16667,-3.21067 -7.16667,-7.16667c0,-3.956 3.21067,-7.16667 7.16667,-7.16667zM86,50.16667c19.78717,0 35.83333,16.04617 35.83333,35.83333c0,19.78717 -16.04617,35.83333 -35.83333,35.83333c-19.78717,0 -35.83333,-16.04617 -35.83333,-35.83333c0,-19.78717 16.04617,-35.83333 35.83333,-35.83333zM86,64.5c-11.87412,0 -21.5,9.62588 -21.5,21.5c0,11.87412 9.62588,21.5 21.5,21.5c11.87412,0 21.5,-9.62588 21.5,-21.5c0,-11.87412 -9.62588,-21.5 -21.5,-21.5z"></path></g></g></svg>
                </span>
            </div>
       
    <nav id="navbar-main" class="navbar navbar-main navbar-expand-lg navbar-transparent navbar-light headroom">
      <div class="container">
        <a class="navbar-brand mr-lg-5" href="<?php echo base_url(); ?>home">
          <img src="../assets/img/brand/blue.png">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse collapse" id="navbar_global">
          <div class="navbar-collapse-header">
            <div class="row">
              <div class="col-6 collapse-brand">
                <a href="home">
                  <img src="../assets/img/brand/blue.png">
                </a>
              </div>
              <div class="col-6 collapse-close">
                <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>

         
          <ul class="navbar-nav align-items-lg-center ml-lg-auto">
              <li class="nav-item">
              <a class="nav-link nav-link-icon disabled" href="#home">
                
                <span class="nav-link-inner--text">Home</span>
              </a>
            </li>
            <li class="nav-item dropdown">
              <a href="#produtos" class="nav-link" data-toggle="dropdown" role="button">
                <i class="ni ni-ui-04 d-lg-none"></i>
                <span class="nav-link-inner--text">Produtos</span>
              </a>
              <div class="dropdown-menu dropdown-menu-xl">
                <div class="dropdown-menu-inner">
                  <a href="http://www.labor-med.com.br/produtos/1/65/FUJIFILM/Endoscopio_Ultra_Sonico_EUS.html" target="_blank" class="media d-flex align-items-center">
                    <div class="icon icon-shape bg-gradient-danger rounded-circle text-white">
                      <img width="50px" src="../assets/img/brand/fuji.png">
                    </div>
                    <div class="media-body ml-3">
                      <h6 class="heading text-danger mb-md-1">Fujifilm</h6>
                      <p class="description d-none d-md-inline-block mb-0">A FUJIFILM traz produtos e soluções inovadoras para um amplo espectro de indústrias e está comprometida com a gestão ambiental e a boa cidadania corporativa.</p>
                    </div>
                  </a>
                  <a href="http://www.labor-med.com.br/produtos/11/77/MAUNA_KEA/Endomicroscopia___Confocal.html" target="_blank" class="media d-flex align-items-center">
                    <div class="icon icon-shape bg-gradient-blue rounded-circle text-white">
                      <img width="50px" src="../assets/img/brand/cell.png">
                    </div>
                    <div class="media-body ml-3">
                      <h6 class="heading text-primary mb-md-1">Cellvizio</h6>
                      <p class="description d-none d-md-inline-block mb-0">Endomicroscopia e interpretação de imagens. Mais recursos educacionais e procedimentos da Conferência Internacional.</p>
                    </div>
                  </a>
                  <a href="http://www.labor-med.com.br/produtos/4/79/ECHOSENS/Fibroscan___Elastrografia_Pulsionada_Controlada.html" target="_blank" class="media d-flex align-items-center">
                    <div class="icon icon-shape bg-gradient-warning rounded-circle text-white">
                      <img width="50px" src="../assets/img/brand/echo.png">
                    </div>
                    <div class="media-body ml-3">
                      <h5 class="heading text-primary mb-md-1">Echosens</h5>
                      <p class="description d-none d-md-inline-block mb-0">A Echosens é uma empresa inovadora de alta tecnologia que oferece uma gama completa de produtos e serviços que auxiliam os médicos na avaliação e no gerenciamento de pacientes com doenças hepáticas crônicas.</p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
            
            <li class="nav-item">
              <a class="nav-link nav-link-icon" href="#sobre">
                
                <span class="nav-link-inner--text">Sobre</span>
              </a>
            </li>

           <li class="nav-item">
              <a class="nav-link nav-link-icon" href="#contato">
                
                <span class="nav-link-inner--text">Contato</span>
              </a>
            </li>


          </ul>

        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
name: "navbar",
}
</script>

<style src="../assets/scss/_main.css">

</style>